import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'beer-search',
    loadChildren: () => import('./beer-search/beer-search.module').then( m => m.BeerSearchPageModule)
  },
	{
    path: 'beer-search2',
    loadChildren: () => import('./beer-search2/beer-search2.module').then( m => m.BeerSearchPageModule2)
  },
	{
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then( m => m.RegistrationPageModule)
  },
	{
    path: 'user',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'taps',
    loadChildren: () => import('./taps/taps.module').then( m => m.TapsPageModule)
  },
  {
    path: 'beers',
    loadChildren: () => import('./beers/beers.module').then( m => m.BeersPageModule)
  },
  {
    path: 'data-modal',
    loadChildren: () => import('./data-modal/data-modal.module').then( m => m.DataModalPageModule)
  },
  {
    path: 'tap-modal',
    loadChildren: () => import('./tap-modal/tap-modal.module').then( m => m.TapModalPageModule)
  },
  {
    path: 'new-tap-modal',
    loadChildren: () => import('./new-tap-modal/new-tap-modal.module').then( m => m.NewTapModalPageModule)
  },
	{
    path: 'alert-modal',
    loadChildren: () => import('./alert-modal2/alert-modal.module').then( m => m.AlertModalPageModule)
  },
  {
    path: 'init-data-modal',
    loadChildren: () => import('./init-data-modal/init-data-modal.module').then( m => m.InitDataModalPageModule)
  },  {
    path: 'keg-manager',
    loadChildren: () => import('./keg-manager/keg-manager.module').then( m => m.KegManagerPageModule)
  },
  {
    path: 'swap-modal',
    loadChildren: () => import('./swap-modal/swap-modal.module').then( m => m.SwapModalPageModule)
  },
	{
    path: 'cleaning-modal',
    loadChildren: () => import('./cleaning-modal/cleaning-modal.module').then( m => m.CleaningModalPageModule)
  },
  {
    path: 'keg-details/:kegId',
    loadChildren: () => import('./keg-details/keg-details.module').then( m => m.KegDetailsPageModule)
  },
  {
    path: 'keg-list',
    loadChildren: () => import('./keg-list/keg-list.module').then( m => m.KegListPageModule)
  },
	{
    path: 'quick-view',
    loadChildren: () => import('./quick-view/quick-view.module').then( m => m.QuickViewPageModule)
  },
	{
    path: 'keg-drawdown/:kegId',
    loadChildren: () => import('./keg-drawdown/keg-drawdown.module').then( m => m.KegDrawdownPageModule)
  },
	{
    path: 'draftlist',
    loadChildren: () => import('./draftlist/draftlist.module').then( m => m.DraftlistPageModule)
  },
	{
    path: 'keg-dashboard',
    loadChildren: () => import('./keg-dashboard/keg-dashboard.module').then( m => m.KegDashboardModule)
  },
	{
    path: 'pricing',
    loadChildren: () => import('./pricing/pricing.module').then( m => m.PricingPageModule)
  },
	{
    path: 'edit-pricing',
    loadChildren: () => import('./edit-pricing-modal/edit-pricing-modal.module').then( m => m.EditPricingModalModule)
  },
	{
    path: 'data-snap',
    loadChildren: () => import('./data-snap/data-snap.module').then( m => m.DataSnapPageModule)
  },
	{
    path: 'need-verification',
    loadChildren: () => import('./need-verification/need-verification.module').then( m => m.NeedVerificationPageModule)
  },
  {
    path: 'data-snap',
    loadChildren: () => import('./data-snap/data-snap.module').then( m => m.DataSnapPageModule)
  },
  {
    path: 'keg-dashboard',
    loadChildren: () => import('./keg-dashboard/keg-dashboard.module').then( m => m.KegDashboardModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
