import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable, from, throwError, of, forkJoin } from 'rxjs';
import { tap, catchError, map, take, switchMap, concatMap, reduce } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { BackendService } from './backend.service';

import { Keg } from './models/keg.model';
import { PourEvent } from './models/keg.model';
import { CleaningEvent } from './models/keg.model';
import { Tap } from './models/tap.model';
import { PricingProfile } from './models/pricing-profile.model';

import { compareTwoStrings } from 'string-similarity';

//models for batch syncing kegs/kegQueue
import { SyncPayload, KegUpdate, TapUpdate, CombinedUpdate } from './models/sync.model';

export interface Beer {
  a: string; //name
  b: string; //beerType
  c: number; //ABV
	location?: string;
	beerId?: string;
	bundleId?: string;
	breweryId?: string;
}

export interface Brewery {
  a: string; // The brewery name
  b: number; // The bundle_id
	c: string; //brewery location
	location: string;
	id: string;
	name: string;
}

interface BeerBundle {
  [key: string]: Beer;
}

export interface DashboardData {
  user: any;
  kegs: any[];
  taps: any[];
	beers: any[];
	breweries: any[];
	cleanings: any[];
	pricing: any[];
}

@Injectable({
  providedIn: 'root',
})
export class DataService {

	private calibration: number = 14; // 14 seconds per pint
	public sub: any = null;
	public breweriesByBundle: any;
  public beersGroupedByBrewery: any;
  public breweryBundleIds: any;
	
	// Get all users
  getAllUsers(): Observable<any> {
    return this.backendService.getAllUsers().pipe(
      catchError((error) => {
        console.error('Error getting all users:', error);
        return throwError(error);
      })
    );
  }
	
	editDuplicate(beerData: any): Observable<any> {
		return this.backendService.editDuplicate(beerData).pipe(
			tap(
				(response) => {
					// Handle success, e.g., show a success toast
					this.displaySuccessToast('Duplicate beer updated successfully.');
				},
				(error) => {
					// Handle error, e.g., show an error toast
					const errorMessage = `Failed to edit duplicate beer. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);
				}
			)
		);
	}

	mergeDuplicates(mergeData: any): Observable<any> {
		return this.backendService.mergeDuplicates(mergeData).pipe(
			tap(
				(response) => {
					// Handle success
					this.displaySuccessToast('Duplicate beers merged successfully.');
				},
				(error) => {
					// Handle error
					const errorMessage = `Failed to merge duplicates. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);
				}
			)
		);
	}

	confirmDuplicate(confirmData: any): Observable<any> {
		return this.backendService.confirmDuplicate(confirmData).pipe(
			tap(
				(response) => {
					// Handle success
					this.displaySuccessToast('Duplicate beer confirmed successfully.');
				},
				(error) => {
					// Handle error
					const errorMessage = `Failed to confirm duplicate. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);
				}
			)
		);
	}
	
	//--BEGIN BEER DUPLICATE FINDER CODE:----------------------------------------------------------
	
	findDuplicateBeers(threshold: number = 0.80): Observable<any[]> {
    return this.dashboardData$.pipe(
      map(data => this.processBeerDuplicates(data.beers, threshold))
    );
  }

  private processBeerDuplicates(beers: any[], threshold: number): any[] {
    if (!beers || !Array.isArray(beers)) {
      throw new Error("Invalid beer data");
    }

    const breweryMap = this.groupBeersByBrewery(beers);
    return this.compareBeersWithinBrewery(breweryMap, threshold);
  }

  private groupBeersByBrewery(beers: any[]): Map<string, any[]> {
    const breweryMap = new Map<string, any[]>();
    beers.forEach(beer => {
      if (beer && beer.brewery) {
        if (!breweryMap.has(beer.brewery)) {
          breweryMap.set(beer.brewery, []);
        }
        breweryMap.get(beer.brewery).push(beer);
      }
    });
    return breweryMap;
  }

  private compareBeersWithinBrewery(breweryMap: Map<string, any[]>, threshold: number): any[] {
    const duplicates = [];
    breweryMap.forEach(beers => {
      for (let i = 0; i < beers.length; i++) {
        for (let j = i + 1; j < beers.length; j++) {
          const similarity = compareTwoStrings(beers[i].beerName, beers[j].beerName);
          if (similarity > threshold) {
            duplicates.push({ beer1: beers[i], beer2: beers[j], similarity });
          }
        }
      }
    });
    return duplicates;
  }
	
	//--END BEER DUPLICATE FINDER CODE:----------------------------------------------------------
	
	calculateKegLevel(keg: Keg): number {
		let totalPourDuration = 0;
		if (keg.validPours){ // cleaning event exclusion dumps non-excluded pours here
			keg.validPours.forEach(pour => {
				totalPourDuration += pour.dur;
			});
		}
		else { //try to just process all pours into a keg level, in case the cleaning exclusion didn't work
			keg.pours.forEach(pour => {
				totalPourDuration += pour.dur;
			});
		}

    const amountDepleted = totalPourDuration / this.calibration;
    let kegLevel = keg.size - amountDepleted;
		
		if (kegLevel < 1) {
      kegLevel = 1;
    }
		
    return kegLevel;
  }

  getKegLevelColor(keg: Keg): string {
    const percentage = this.calculateKegLevel(keg) / keg.size;
    if (percentage > 0.3) {
      return 'green';
    } else if (percentage > 0.15) {
      return 'yellow';
    } else {
      return 'red';
    }
  }
	
  private dashboardDataSubject: BehaviorSubject<DashboardData> = new BehaviorSubject<DashboardData>({
    user: null,
    kegs: [],
    taps: [],
		beers: [],
		breweries: [],
		cleanings: [],
		pricing: []
  });
	
	public dashboardData$: Observable<DashboardData> = this.dashboardDataSubject.asObservable();
	
	private inventorySubject: BehaviorSubject<Keg[]> = new BehaviorSubject<Keg[]>([]);
	public inventory$: Observable<Keg[]> = this.inventorySubject.asObservable();
	
	private dataUrl = 'assets/data/beers.json';
	private beersUrl = 'assets/data/beers_grouped_by_brewery.json'; // lookup table for beers based on brewery_id (NOT bundle_id)
	private breweryUrl = 'assets/data/brewery_bundle_ids.json'; // lookup table for short brewery name --> bundle_id
	private bundlesUrl = 'assets/data/breweries_by_bundle.json'; // lookup table for bundle --> brewery_ids ... can contain multiple breweries per short phrase but usually just 1
  
  constructor(private backendService: BackendService, private toastController: ToastController, private http: HttpClient, public auth: AuthService) {
		this.dashboardDataSubject.subscribe((dashboardData) => {
			let inventoryKegs = dashboardData.kegs.filter((keg) => keg.kegStatus === 'INVENTORY');
			inventoryKegs = this.sortInventoryKegs(inventoryKegs);
			this.inventorySubject.next(inventoryKegs);
		});
		
		this.getBreweriesByBundle().subscribe(data => {
      this.breweriesByBundle = data;
    });
    this.getBeersGroupedByBrewery().subscribe(data => {
      this.beersGroupedByBrewery = data;
    });
    this.getBreweryBundleIds().subscribe(data => {
      this.breweryBundleIds = data;
    });
  }
	
	sortInventoryKegs(kegs: Keg[]): Keg[] {
		return kegs.sort((a, b) => {
			// Check if validPours exists and is not empty; if not, treat as if empty array
			const aValidPoursLength = a.validPours ? a.validPours.length : 0;
			const bValidPoursLength = b.validPours ? b.validPours.length : 0;

			// First, prioritize kegs with non-empty validPours arrays
			if (aValidPoursLength > 0 && bValidPoursLength === 0) {
				return -1; // a should come before b
			}
			if (bValidPoursLength > 0 && aValidPoursLength === 0) {
				return 1; // b should come before a
			}

			// Then, sort by dateAdded
			return b.dateAdded - a.dateAdded; // For descending order by date
		});
	}
	
	getRandomString(): string {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const length = Math.floor(Math.random() * 10001); // Random length between 0 and 10000
		let result = '';

		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			result += characters[randomIndex];
		}

		return result;
	}
	
	testError() {
	
		this.displayErrorToast("A test error message:" + this.getRandomString());
	
	}
	
	//library of beers, stored locally, combined with beers from the db
  getBeers(): Observable<Beer[]> {
    // Fetch local beers
    const localBeers$ = this.http.get<Beer[]>(this.dataUrl);

    // Get beers from the BehaviorSubject
    const databaseBeers$ = this.dashboardDataSubject.asObservable().pipe(
			take(1), 
			map(dashboardData => dashboardData.beers.map(beer => ({ 
					a: beer.beerName, 
					b: beer.brewery, 
					c: beer.location, 
					d: beer.beerStyle, 
					e: beer.abv 
				})))
		);
    
    // Combine the two beer arrays into a single array
    return forkJoin([localBeers$, databaseBeers$]).pipe(
      map(([localBeers, databaseBeers]) => [...localBeers, ...databaseBeers])
    );
	}
	
	getBreweriesByBundle(): Observable<any> {
    return this.http.get(this.bundlesUrl).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBeersGroupedByBrewery(): Observable<any> {
    return this.http.get(this.beersUrl).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBreweryBundleIds(): Observable<any> {
    return this.http.get(this.breweryUrl).pipe(
      map(data => {
        return data;
      })
    );
  }
	
	getBeersFromBreweryBundle(bundleId: number): any[] {
		const breweriesInBundle = this.breweriesByBundle[bundleId]; // get all breweries in the bundle
		const allBeers = [];
		breweriesInBundle.forEach(brewery => {
			const beers = this.beersGroupedByBrewery[brewery.a]; // 'a' is breweryId
			let beersArray = Object.keys(beers).map((beerId) => {
				return { 
					beerId: parseInt(beerId), 
					breweryId: brewery.a,
					brewery: brewery.b, 
					location: brewery.c,
					bundleId: bundleId,
					...beers[beerId] 
				};
			});
			allBeers.push(...beersArray); // add beers to the allBeers array
		});
		return allBeers;
	}
	
	getBreweries(): Observable<Brewery[]> {
		return this.http.get<Brewery[]>(this.breweryUrl);
	}
	
	//helper functions
	private sortTapsDescending(taps: Tap[]): Tap[] {
    return taps.sort((a, b) => {
        const tapNumberA = parseInt(a.tapName.replace("Tap ", ""), 10);
        const tapNumberB = parseInt(b.tapName.replace("Tap ", ""), 10);

        if (tapNumberA > tapNumberB) {
            return 1;
        }
        if (tapNumberA < tapNumberB) {
            return -1;
        }
        return 0;
    });
	}
	
	private sortPricingProfiles(profiles: PricingProfile[], defaultProfileId: string): PricingProfile[] {
		return profiles.sort((a, b) => {
			if (a.id === defaultProfileId) {
				return -1;
			}
			if (b.id === defaultProfileId) {
				return 1;
			}
			return 0;
		});
	}
	
	kegIdsToKegObjects(kegIds: string[], allKegs: any[]): Keg[] {

		// Create an array to store the keg objects
		const kegObjects: Keg[] = [];

		// Iterate through the kegIds and find the corresponding keg objects
		for (const kegId of kegIds) {
			const kegObject = allKegs.find((keg) => keg.id === kegId);

			// If a keg object is found, push it to the kegObjects array
			if (kegObject) {
				kegObjects.push(kegObject);
			}
		}

		return kegObjects;
	}
	
	kegIdsToKegObjects2(kegIds: string[]): Keg[] { //takes 1 argument, using local dashboardData

    // Create an array to store the keg objects
    const kegObjects: Keg[] = [];

    // Reference to allKegs in behaviorSubject
    const allKegs: Keg[] = this.dashboardDataSubject.value.kegs;

    // Iterate through the kegIds and find the corresponding keg objects
    for (const kegId of kegIds) {
        const kegObject = allKegs.find((keg) => keg.id === kegId);

        // If a keg object is found, push it to the kegObjects array
        if (kegObject) {
            kegObjects.push(kegObject);
        }
    }

    return kegObjects;
	}
	
	kegObjectsToKegIds(kegs: Keg[]): string[] {
		return kegs.map((keg) => keg.id);
	}
  
  async displayErrorToast(message: string): Promise<void> {
		
		if(message.includes("Missing Refresh Token")){ //token expired, redirect to login
			console.log("TOKEN HAS EXPIRED! Need to re-login!");			
			this.auth.loginWithRedirect();		
		}
		
		const toast = await this.toastController.create({
			message: message,
			buttons: [{ side: 'start', text: 'X', role: 'cancel', 
			handler: () => {
        console.log('error dismissed');
      }, }],
			position: 'top',
			color: 'danger',
		});
			await toast.present();
  }
  
  async displaySuccessToast(message: string): Promise<void> {
		const toast = await this.toastController.create({
			message: message,
			duration: 3000,
			position: 'top',
			color: 'success',
		});
			await toast.present();
	}

  updateDashboardData(data: DashboardData): void {
    this.dashboardDataSubject.next(data);
  }
	
	updateTapKegQueue(tapId: string, kegQueue: string[]): Observable<any> {
		return this.backendService.updateTapKegQueue(tapId, kegQueue).pipe(
			tap(
				() => {
					this.displaySuccessToast('Tap keg queue updated successfully.');
				},
				(error) => {
					const errorMessage = `Failed to update tap keg queue. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);
				}
			)
		);
	}

	updateKegById(kegId: string, updateData: any): Observable<any> {
		return this.backendService.updateKegById(kegId, updateData).pipe(
			tap(
				(updatedKeg) => {
					this.displaySuccessToast('Keg updated successfully.');
					// Update the BehaviorSubject
					const currentDashboardData = this.dashboardDataSubject.getValue();
					const updatedKegs = currentDashboardData.kegs.map(keg => keg.id === updatedKeg.id ? updatedKeg : keg);
					// Create a new dashboardData object with the updated kegs array
					const updatedDashboardData: DashboardData = {
						...currentDashboardData,
						kegs: updatedKegs
					};
					this.dashboardDataSubject.next(updatedDashboardData);
					this.refreshKegLevels(); //update displayed keg levels - need to do this if user changes anything that affects the pour array
				},
				(error) => {
					const errorMessage = `Failed to update keg. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);
				}
			)
		);
	}
	
	getUserDefaultPricingProfile(): string | null {
		const currentDashboardData = this.dashboardDataSubject.getValue();
		return currentDashboardData.user.defaultPricingProfile || null;
	}
	
	updateUser(updateData: any): Observable<any> {
		console.log("updating user.........");
		console.log(updateData);
		return this.backendService.updateUser(updateData).pipe(
			tap(
				(updatedUser) => {
					this.displaySuccessToast('User updated successfully.');
					// Update the BehaviorSubject
					let currentDashboardData = this.dashboardDataSubject.getValue();
					let changedCalibration = false;
					
					if(currentDashboardData.user.calibration !== updateData.calibration){
						console.log("calibration changed, updating keg levels...");
						changedCalibration = true;
					}
					
					currentDashboardData.user = updateData;
					
					// Sort the pricing profiles with the default profile first (need to do this here, if a new default profile is set
					currentDashboardData.pricing = this.sortPricingProfiles(currentDashboardData.pricing, currentDashboardData.user.defaultPricingProfile);
					
					this.dashboardDataSubject.next(currentDashboardData);
					
					if(changedCalibration){
						if(updateData.calibration) this.calibration = updateData.calibration; //calibration value used for keg level calc is updated here
						else this.calibration = 14; //set to default if not set
						
						this.refreshKegLevels(); //update displayed keg levels with the new calibration value
					}
				},
				(error) => {
					console.log("user update failed!");
					const errorMessage = `Failed to update user info. Error info: ${JSON.stringify(error)}`;
					console.log(errorMessage);
					this.displayErrorToast(errorMessage);
				}
			)
		);
	}
	
	getDashboardFromAPI() {
    this.backendService.fetchDashboardData().pipe(
        switchMap(userData => {
				
            // Sort the taps in descending order by name
            userData.taps = this.sortTapsDescending(userData.taps);
						
						// Sort the pricing profiles with the default profile first
						userData.pricing = this.sortPricingProfiles(userData.pricing, userData.user.defaultPricingProfile);

            return this.backendService.fetchKegLevels().pipe(
                map(tappedKegsWithLevels => {
                    // Merge the keg levels data with the kegs data in the userData
										
										let refreshedKegs = [];
										
										if (tappedKegsWithLevels)	{ //only attempt to match keg levels if there are some tapped kegs
											for (const keg of userData.kegs) {
                        const kegLevelData = tappedKegsWithLevels.find(levelKeg => levelKeg.id === keg.id);
                        if (kegLevelData) {
                            console.log("appending pours!")
                            keg.pours = kegLevelData.pours;
														keg.batteryPercent = kegLevelData.batteryPercent;
														keg.timeSinceLastSeen = kegLevelData.timeSinceLastSeen;
														refreshedKegs.push(keg);
                        }  
											}
											// Transform kegQueue from keg IDs to Keg objects
											for (const tap of userData.taps) {
													tap.kegQueue = this.kegIdsToKegObjects(tap.kegQueue, userData.kegs);
											}
											
											//now update all the 0th kegs under tap.kegQueue by matching the hardwareIDs
											for (const tap of userData.taps) {
												for (const keg of refreshedKegs) {
													if(tap.hardwareID === keg.hardwareID) {
														tap.kegQueue[0] = keg; //update the 0th keg
														break;
													}
												}							
											}
										}										

                    // Now that we have updated the userData with keg levels data,
                    // return it so it can be pushed into the BehaviorSubject
                    return userData;
                })
            );
        })
    ).subscribe(
        userData => {
						console.log("setting userData.user.calibration");
						console.log(userData.user.calibration);
						
						if(userData.user.calibration){ //set the 1-pint-pour time calibration if the user has set it, otherwise default to 14 (set above)
							this.calibration = userData.user.calibration;
						}
				
						// Apply cleaning events to kegs
						for (const keg of userData.kegs) {
							this.reconcilePoursAndCleanings(keg, userData.cleanings); //turns pours into validPours, if they are not during a cleaning event
							// Calculate keg levels and colors for each keg with status 'COMPLETED' or 'TAPPED'
							if (keg.pours && keg.pours.length > 0) {
								keg['level'] = this.calculateKegLevel(keg);
								keg['levelColor'] = this.getKegLevelColor(keg);
							} else {
								keg['level'] = keg.size;
								keg['levelColor'] = 'green'; // or whatever color you want to represent 100%
							}
						}
						
						// Push the updated userData into the BehaviorSubject
            this.dashboardDataSubject.next(userData);

            // Display a success toast
            this.displaySuccessToast('User info fetched successfully.');
        },
        error => {
            // Handle errors here
						const errorMessage = `Failed to fetch user dashboard and keg levels. Error info: ${JSON.stringify(error)}`;
						this.displayErrorToast(errorMessage);
        }
    );
	}
	
	refreshKegLevels(): void {
		this.backendService.fetchKegLevels().subscribe(
			(tappedKegsWithLevels) => {
			
				const userData = this.dashboardDataSubject.getValue();

				let refreshedKegs = [];
			
				if (tappedKegsWithLevels)	{ //only attempt to match keg levels if there are some tapped kegs
					console.log("DEBUG: kegs with levels:", tappedKegsWithLevels);
					for (const keg of userData.kegs) {
						const kegLevelData = tappedKegsWithLevels.find(levelKeg => levelKeg.id === keg.id);
						if (kegLevelData) {
								console.log("appending pours!")
								keg.pours = kegLevelData.pours;
								keg.batteryPercent = kegLevelData.batteryPercent;
								keg.timeSinceLastSeen = kegLevelData.timeSinceLastSeen;								
								refreshedKegs.push(keg);
						}
					}
					//now update all the 0th kegs under tap.kegQueue by matching the hardwareIDs
					for (const tap of userData.taps) {
						for (const keg of refreshedKegs) {
							if(tap.hardwareID === keg.hardwareID) {
								tap.kegQueue[0] = keg; //update the 0th keg
								break;
							}
						}							
					}
				}

				// Apply cleaning events to kegs
				for (const keg of userData.kegs) {
					// Calculate keg levels and colors for each keg with status 'COMPLETED' or 'TAPPED'
					if (keg.pours && keg.pours.length > 0) {
						this.reconcilePoursAndCleanings(keg, userData.cleanings);
						keg['level'] = this.calculateKegLevel(keg);
						keg['levelColor'] = this.getKegLevelColor(keg);
					} else {
						keg['level'] = keg.size;
						keg['levelColor'] = 'green'; // or whatever color you want to represent 100%
					}
				}
				
				//now update the BehaviorSubject, which will push into any subscribers automatically
				this.dashboardDataSubject.next(userData);
				
				// Display a success toast
				this.displaySuccessToast('Keg levels updated successfully.');
			},
			(error) => {
				// Handle the error received from the backend service
				const errorMessage = `Failed to update keg levels. Error info: ${JSON.stringify(error)}`;
				this.displayErrorToast(errorMessage);
			}
		);
	}

	createKeg(kegData: any): void {
		this.backendService.createKeg(kegData).subscribe(
			(newKeg) => {
				const currentData = this.dashboardDataSubject.getValue();
				currentData.kegs.push(newKeg);
				this.dashboardDataSubject.next(currentData);
				
				// Display a success toast
				this.displaySuccessToast('Keg created successfully.');
			},
			(error) => {
				// Handle the error received from the backend service
				const errorMessage = `Failed to create keg. Error info: ${JSON.stringify(error)}`;
				this.displayErrorToast(errorMessage);
			}
		);
	}
	
	createBeer(beerData: any): Observable<any> {
    return this.backendService.createBeer(beerData).pipe(
      tap(
        (newBeer) => {
          const currentData = this.dashboardDataSubject.getValue();
          currentData.beers.push(newBeer);
          this.dashboardDataSubject.next(currentData);

          // Display a success toast
          this.displaySuccessToast('Beer created successfully.');
        },
        (error) => {
          // Handle the error received from the backend service
          const errorMessage = `Failed to create beer. Error info: ${JSON.stringify(error)}`;
          this.displayErrorToast(errorMessage);
        }
      )
    );
  }
	
	createBrewery(breweryData: any): Observable<any> {
			return this.backendService.createBrewery(breweryData).pipe(
					tap(newBrewery => {
							const currentData = this.dashboardDataSubject.getValue();
							currentData.breweries.push(newBrewery);
							this.dashboardDataSubject.next(currentData);
							this.displaySuccessToast('Brewery created successfully.');
					}),
					catchError(error => {
							const errorMessage = `Failed to create brewery. Error info: ${JSON.stringify(error)}`;
							this.displayErrorToast(errorMessage);
							throw error; // it re-throws the error so it can be caught later in the subscription if needed
					})
			);
	}
	
	swapNextKeg(tapId: string, swapTime?: number): Observable<any> {
		return this.backendService.nextKeg(tapId, swapTime).pipe(
			tap(() => {
				// You can update the dashboard data here if necessary, similar to how it's done in `createKeg`
				const currentData = this.dashboardDataSubject.getValue();
				//find the tap by id
				for (const tap of currentData.taps) {
					if(tap.id === tapId){
						//handle the swap update on this tap
						if (tap.kegQueue.length > 1) { // make sure there's a second keg to swap with
							const removedKeg = tap.kegQueue.splice(1, 1)[0]; // Remove the second keg from the array
							tap.kegQueue.splice(0, 1, removedKeg); // Replace the first keg with the removed keg
							
							let swapTimestamp = swapTime;
							if(!swapTime) swapTimestamp = Date.now();
							
							 // Set the first kegStatus to 'TAPPED'
							const kegToUpdate = tap.kegQueue[0];
							kegToUpdate.kegStatus = 'TAPPED';
							kegToUpdate.level = kegToUpdate.size;
							kegToUpdate.levelColor = 'green';
							kegToUpdate.tappedTime = swapTimestamp;
							
							if(kegToUpdate.validPours && kegToUpdate.validPours.length > 0){ //previously inventoried keg with prior pours
								kegToUpdate.pours = kegToUpdate.validPours; //keep the valid pours
							}
							else { //fresh keg
								kegToUpdate.pours = [];
							}
						}
						else { // there is no queued keg
							tap.kegQueue.splice(0, 1); // Remove the first keg from the array
							console.log("no queued keg");
						}
						break;
					}
				}
				
				this.dashboardDataSubject.next(currentData);
				
				// Display a success toast
				this.displaySuccessToast('Swapped to the next keg successfully.');
			}),
			catchError((error) => {
				// Handle the error received from the backend service
				const errorMessage = `Failed to swap to the next keg. Error info: ${JSON.stringify(error)}`;
				this.displayErrorToast(errorMessage);
				return throwError(error);
			})
		);
	}
	
	getUser(): Observable<any> {
		return this.backendService.getUser().pipe(
			tap(
				(thisUser) => {
					const currentData = this.dashboardDataSubject.getValue();
					currentData.user = thisUser;
					this.dashboardDataSubject.next(currentData);
					// Display a success toast
					this.displaySuccessToast('User fetched successfully.');
				},
				(error) => {
				
					if (error.status === 404) {
					// Normal flow, ignore the toast and show register page
					} else {
						console.error('An error occurred while fetching the user:', error);
						// Handle the error received from the backend service
						const errorMessage = `Failed to fetch user. Error info: ${JSON.stringify(error)}`;
						this.displayErrorToast(errorMessage);
					}
				}
			),
			catchError((error) => {
				// In case of any error, rethrow the error so it can be handled by the caller
				return throwError(error);
			})
		);
	}
	
	getAllPours(): Observable<any> { //get pour data for tapped and completed kegs, append pour data to behaviorSubject for later consumption
		return this.backendService.getAllPours().pipe(
			tap(
				(kegsWithPours) => {
					const currentData = this.dashboardDataSubject.getValue();
					
					console.log('DEBUG: updating pours:', kegsWithPours);
					
					if (kegsWithPours)	{ //only attempt to match keg levels if there are some tapped kegs
						console.log("DEBUG: kegs with levels:", kegsWithPours);
						for (const keg of currentData.kegs) {
							const kegLevelData = kegsWithPours.find(levelKeg => levelKeg.id === keg.id);
							if (kegLevelData) {
								console.log("appending pours!")
								keg.pours = kegLevelData.pours;
								
								// Apply cleaning events to kegs
								this.reconcilePoursAndCleanings(keg, currentData.cleanings);
								// Calculate keg levels and colors for each keg with status 'COMPLETED' or 'TAPPED'
								if (keg.pours && keg.pours.length > 0) {
									keg['level'] = this.calculateKegLevel(keg);
									keg['levelColor'] = this.getKegLevelColor(keg);
								} else {
									keg['level'] = keg.size;
									keg['levelColor'] = 'green'; // or whatever color you want to represent 100%
								}
							}									
						}
					}
					
					//update the BehaviorSubject
					this.dashboardDataSubject.next(currentData);
					// Display a success toast
					this.displaySuccessToast('All pour data fetched successfully.');
				},
				(error) => {
					console.error('An error occurred while fetching all pour data:', error);
					// Handle the error received from the backend service
					const errorMessage = `Failed to fetch all pour data. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);				
				}
			),
			catchError((error) => {
				// In case of any error, rethrow the error so it can be handled by the caller
				return throwError(error);
			})
		);
	}
	
	createUser(userData: any): void {
		this.backendService.createUser(userData).subscribe(
			(newUser) => {
				const currentData = this.dashboardDataSubject.getValue();
				currentData.user = newUser;
				this.dashboardDataSubject.next(currentData);
				// Display a success toast
				this.displaySuccessToast('User created successfully.');
			},
			(error) => {
				// Handle the error received from the backend service
				const errorMessage = `Failed to create user. Error info: ${JSON.stringify(error)}`;
				this.displayErrorToast(errorMessage);
			}
		);
	}
	
	createTap(tapData: any): Observable<any> {
		return this.backendService.createTap(tapData).pipe(
			tap((newTap) => {
				const currentData = this.dashboardDataSubject.getValue();
				currentData.taps.push(newTap);
				this.dashboardDataSubject.next(currentData);
				// Display a success toast
				this.displaySuccessToast('Taps initialized successfully.');
			}),
			catchError((error) => {
				// Handle the error received from the backend service
				const errorMessage = `Failed to create tap. Error info: ${JSON.stringify(error)}`;
				this.displayErrorToast(errorMessage);
				return throwError(error);
			})
		);
	}
	
	updateKegs(kegs: any[]): void {
		const currentData = this.dashboardDataSubject.getValue();
		currentData.kegs = kegs;
		this.dashboardDataSubject.next(currentData);
  }
	
	updateKeg(kegs: any[]): void {
		alert("not implemented yet!");
  }

  deleteKeg(id: string): void {
    this.backendService.deleteKeg(id).subscribe(() => {
		const currentData = this.dashboardDataSubject.getValue();
		currentData.kegs = currentData.kegs.filter((keg) => keg.id !== id);
		this.dashboardDataSubject.next(currentData);
    });
  }
	
	deleteKegById(kegId: string): Observable<any> {
		return this.backendService.deleteKeg(kegId).pipe(
			tap(
				() => { // Update the BehaviorSubject
					const currentDashboardData = this.dashboardDataSubject.getValue();

					// Update the kegs array by filtering out the deleted keg
					const updatedKegs = currentDashboardData.kegs.filter((keg) => keg.id !== kegId);

					// Update the BehaviorSubject with the new dashboard data
					this.dashboardDataSubject.next({
						...currentDashboardData,
						kegs: updatedKegs,
					});
					this.displaySuccessToast('Keg deleted successfully.');
				},
				(error) => {
					const errorMessage = `Failed to delete keg. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);
				}
			)
		);
	}
	
	createCleaningEvent(eventData: any): Observable<any> {
		return new Observable((observer) => {
			this.backendService.createCleaningEvent(eventData).subscribe(
				(newEvent) => {
					const currentData = this.dashboardDataSubject.getValue();
					currentData.cleanings.push(newEvent);
					this.dashboardDataSubject.next(currentData);
					
					// Display a success toast
					this.displaySuccessToast('Cleaning event created successfully.');
					
					// Notify the observer of success
					observer.next(true);
					observer.complete();
				},
				(error) => {
					// Handle the error received from the backend service
					const errorMessage = `Failed to create cleaning event. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);
					
					// Notify the observer of the error
					observer.error(error);
				}
			);
		});
	}

	deleteCleaningEventById(eventId: string): void {
		this.backendService.deleteCleaningEvent(eventId).subscribe(
			() => {
				// Update the BehaviorSubject
				const currentData = this.dashboardDataSubject.getValue();
				const updatedEvents = currentData.cleanings.filter(event => event.id !== eventId);
				currentData.cleanings = updatedEvents;
				this.dashboardDataSubject.next(currentData);
				
				// Display a success toast
				this.displaySuccessToast('Cleaning event deleted successfully.');
			},
			(error) => {
				const errorMessage = `Failed to delete cleaning event. Error info: ${JSON.stringify(error)}`;
				this.displayErrorToast(errorMessage);
			}
		);
	}

	updateCleaningEventById(eventId: string, updateData: any): Observable<any> {
		return this.backendService.updateCleaningEventById(eventId, updateData).pipe(
			tap(
				(updatedEvent) => {
					this.displaySuccessToast('Cleaning event updated successfully.');
					// Update the BehaviorSubject
					const currentDashboardData = this.dashboardDataSubject.getValue();
					const updatedEvents = currentDashboardData.cleanings.map(event => event.id === updatedEvent.id ? updatedEvent : event);
					// Create a new dashboardData object with the updated cleanings array
					const updatedDashboardData: DashboardData = {
						...currentDashboardData,
						cleanings: updatedEvents
					};
					this.dashboardDataSubject.next(updatedDashboardData);
				},
				(error) => {
					const errorMessage = `Failed to update cleaning event. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);
				}
			)
		);
	}
	
	// Function to create an array of valid time intervals from cleaning events
	getValidIntervals = (cleaningEvents: CleaningEvent[], hardwareID: string): [number, number][] => {
		const sortedEvents = cleaningEvents.sort((a, b) => a.startTime - b.startTime);
		let invalidIntervals: [number, number][] = [];

		for (const event of sortedEvents) {
			if (event.eventType === 'global' || (event.eventType === 'singleTap' && event.hardwareID === hardwareID)) {
				invalidIntervals.push([event.startTime, event.endTime]);
			}
		}

		return invalidIntervals;
	};
	
	isoToTimestamp = (isoString: string): number => {
		return new Date(isoString).getTime();
	};
	

	reconcilePoursAndCleanings = (keg: Keg, cleaningEvents: CleaningEvent[]): void => {
		const invalidIntervals = this.getValidIntervals(cleaningEvents, keg.hardwareID);
		if (keg.pours) {
			keg.validPours = keg.pours.filter(pour => !invalidIntervals.some(interval => this.isoToTimestamp(pour.time) >= interval[0] && this.isoToTimestamp(pour.time) <= interval[1]));
			keg.excludedPours = keg.pours.filter(pour => invalidIntervals.some(interval => this.isoToTimestamp(pour.time) >= interval[0] && this.isoToTimestamp(pour.time) <= interval[1]));
		}
	};
	
	returnAllKegsToInventory(tapId: string): Observable<any> {
    return this.backendService.returnAllKegsToInventory(tapId).pipe(
        tap(() => {
            const currentData = this.dashboardDataSubject.getValue();
            const tap = currentData.taps.find(tap => tap.id === tapId);

            if (tap) {
                console.log('KegQueue:', tap.kegQueue);
                console.log('Available Keg IDs:', currentData.kegs.map(keg => keg.id));

                // Iterate over tap.kegQueue, now knowing it's an array of objects with ids
                tap.kegQueue.forEach(kegQueueItem => {
                    const kegId = kegQueueItem.id;
                    const keg = currentData.kegs.find(keg => keg.id === kegId);
                    if (keg) {
                        keg.kegStatus = 'INVENTORY'; // Update keg status
                        console.log('Keg updated to INVENTORY:', kegId);
                    }
                });

                // Clear the tap's kegQueue after updating kegs' status
                tap.kegQueue = [];
            }

            this.dashboardDataSubject.next(currentData);
            this.displaySuccessToast('All kegs returned to inventory successfully. Keg queue has been cleared.');
        }),
        catchError((error) => {
            const errorMessage = `Failed to return all kegs to inventory with pours. Error info: ${JSON.stringify(error)}`;
            this.displayErrorToast(errorMessage);
            return throwError(error);
        })
    );
	}
	
	//data service functions for CRUD pricing profiles
	createPricingProfile(profileData: any): Promise<any> {
		console.log("creating pricing profile...");
		return this.backendService.createPricingProfile(profileData).pipe(
			tap(
				(createdProfile) => {
					this.displaySuccessToast('Pricing profile created successfully.');
					// Update the BehaviorSubject
					let currentDashboardData = this.dashboardDataSubject.getValue();
					currentDashboardData.pricing.push(createdProfile);
					
					this.dashboardDataSubject.next(currentDashboardData);
					return createdProfile;
				},
				(error) => {
					const errorMessage = `Failed to create pricing profile. Error info: ${JSON.stringify(error)}`;
					this.displayErrorToast(errorMessage);
				}
			),
			catchError((error) => {
				console.error('Error creating pricing profile:', error);
				return of(null);
			})
		).toPromise();
	}

  updatePricingProfile(profileId: string, profileData: any): void {
    this.backendService.updatePricingProfile(profileId, profileData).subscribe(
      (updatedProfile) => {
        const currentData = this.dashboardDataSubject.getValue();
        const index = currentData.pricing.findIndex((profile) => profile.id === profileId);
        if (index !== -1) {
          currentData.pricing[index] = updatedProfile;
          this.dashboardDataSubject.next(currentData);
          this.displaySuccessToast('Pricing profile updated successfully.');
        }
      },
      (error) => {
        const errorMessage = `Failed to update pricing profile. Error info: ${JSON.stringify(error)}`;
        this.displayErrorToast(errorMessage);
      }
    );
  }

  deletePricingProfile(profileId: string): void {
    this.backendService.deletePricingProfile(profileId).subscribe(
      () => {
        const currentData = this.dashboardDataSubject.getValue();
        currentData.pricing = currentData.pricing.filter((profile) => profile.id !== profileId);
        this.dashboardDataSubject.next(currentData);
        this.displaySuccessToast('Pricing profile deleted successfully.');
      },
      (error) => {
        const errorMessage = `Failed to delete pricing profile. Error info: ${JSON.stringify(error)}`;
        this.displayErrorToast(errorMessage);
      }
    );
  }
	
	//BEGIN keg/tap sync functions
	// New sync function
	syncUpdates(updates: SyncPayload): Observable<any> {
    const BATCH_SIZE = 50;
    const batches: SyncPayload[] = this.createBatches(updates, BATCH_SIZE);
    return forkJoin(batches.map(batch => this.backendService.sendSyncRequest(batch))).pipe(
      tap(
        () => {
          console.log('Keg queue and statuses synced successfully.');
        },
        (error) => {
          const errorMessage = `Failed to sync keg queue and statuses. Error info: ${JSON.stringify(error)}`;
          this.displayErrorToast(errorMessage);
        }
      )
    );
  }

  private createBatches(updates: SyncPayload, batchSize: number): SyncPayload[] {
    const batches: SyncPayload[] = [];
    const allUpdates: CombinedUpdate[] = [
      ...(updates.k || []).map(update => ({ type: 'k', update } as CombinedUpdate)),
      ...(updates.t || []).map(update => ({ type: 't', update } as CombinedUpdate))
    ];

    for (let i = 0; i < allUpdates.length; i += batchSize) {
      const batch = allUpdates.slice(i, i + batchSize);
      const batchPayload: SyncPayload = { k: [], t: [] };

      batch.forEach(item => {
        if (item.type === 'k') {
          batchPayload.k.push(item.update);
        } else {
          batchPayload.t.push(item.update);
        }
      });

      batches.push(batchPayload);
    }

    return batches;
  }
	
	// Get all users
  getLogs(): Observable<any> {
    return this.backendService.getLogs().pipe(
      catchError((error) => {
        console.error('Error getting user logs:', error);
        return throwError(error);
      })
    );
  }
  
  createBreweries(breweriesData: any[]): Observable<any[]> {
    // Split into chunks of 25 items due to DynamoDB batch operation limits
    const chunkSize = 25;
    const chunks = [];
    
    for (let i = 0; i < breweriesData.length; i += chunkSize) {
      chunks.push(breweriesData.slice(i, i + chunkSize));
    }

    // Process each chunk sequentially using concatMap
    return from(chunks).pipe(
      concatMap(chunk => this.backendService.createBreweries(chunk)),
      reduce((acc: any[], curr: any[]) => acc.concat(curr), []),
      tap(newBreweries => {
        const currentData = this.dashboardDataSubject.getValue();
        currentData.breweries.push(...newBreweries);
        this.dashboardDataSubject.next(currentData);
        this.displaySuccessToast(
          `Successfully created ${newBreweries.length} ${
            newBreweries.length === 1 ? 'brewery' : 'breweries'
          }.`
        );
      }),
      catchError(error => {
        this.displayErrorToast('Failed to create breweries.');
        throw error;
      })
    );
  }
  
  createBeers(beersData: any[]): Observable<any[]> {
    // Split into chunks of 25 items due to DynamoDB batch operation limits
    const chunkSize = 25;
    const chunks = [];
    
    for (let i = 0; i < beersData.length; i += chunkSize) {
      chunks.push(beersData.slice(i, i + chunkSize));
    }

    // Process each chunk sequentially using concatMap
    return from(chunks).pipe(
      concatMap(chunk => this.backendService.createBeers(chunk)),
      reduce((acc: any[], curr: any[]) => acc.concat(curr), []),
      tap(newBeers => {
        const currentData = this.dashboardDataSubject.getValue();
        currentData.beers.push(...newBeers);
        this.dashboardDataSubject.next(currentData);
        this.displaySuccessToast(
          `Successfully created ${newBeers.length} ${
            newBeers.length === 1 ? 'beer' : 'beers'
          }.`
        );
      }),
      catchError(error => {
        this.displayErrorToast('Failed to create beers.');
        throw error;
      })
    );
  }
  
  createKegs(kegsData: any[]): Observable<any[]> {
    // Split into chunks of 25 items due to DynamoDB batch operation limits
    const chunkSize = 25;
    const chunks = [];
    
    for (let i = 0; i < kegsData.length; i += chunkSize) {
      chunks.push(kegsData.slice(i, i + chunkSize));
    }

    // Process each chunk sequentially using concatMap
    return from(chunks).pipe(
      concatMap(chunk => this.backendService.createKegs(chunk)),
      reduce((acc: any[], curr: any[]) => acc.concat(curr), []),
      tap(newKegs => {
        const currentData = this.dashboardDataSubject.getValue();
        currentData.kegs.push(...newKegs);
        this.dashboardDataSubject.next(currentData);
        this.displaySuccessToast(
          `Successfully created ${newKegs.length} ${
            newKegs.length === 1 ? 'keg' : 'kegs'
          }.`
        );
      }),
      catchError(error => {
        this.displayErrorToast('Failed to create kegs.');
        throw error;
      })
    );
  }
  
  uploadAndAnalyzeImage(imageBlob: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('image', imageBlob, 'image.jpg');

      this.backendService.analyzeImage(formData).subscribe(
        (result) => {
          console.log('Analysis result:', result);
          this.displaySuccessToast('Image analyzed successfully.');
          resolve(result);
        },
        (error) => {
          console.error('Error analyzing image:', error);
          const errorMessage = `Failed to analyze image. Error info: ${JSON.stringify(error)}`;
          this.displayErrorToast(errorMessage);
          reject(error);
        }
      );
    });
  }
}