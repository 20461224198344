import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthConfig, AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { domain, clientId, callbackUri, audience } from './auth.config';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule here

import { PushService } from './push.service'; //push notification service

import { HighchartsChartModule } from 'highcharts-angular';

const apiURL = 'https://prod2.poursoulsystems.com/admin'

const config: AuthConfig = {
  domain,
  clientId,
  authorizationParams: {
    redirect_uri: callbackUri,
		audience,
		scope: 'openid profile email verified:true admin:true'
  },
  // cacheLocation: "localstorage",
  useRefreshTokens: true,
  httpInterceptor: {
	  allowedList: [`${apiURL}/*`],
  },
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
				FormsModule,
				ReactiveFormsModule, // Add ReactiveFormsModule to the imports array
        AuthModule.forRoot(config),
        HttpClientModule,
				HighchartsChartModule,
		DragDropModule,
    ],
    providers: [PushService,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
	],
    bootstrap: [AppComponent]
})
export class AppModule {}
