import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

import { DashboardData } from './data.service';
import { SyncPayload } from './models/sync.model';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  private baseUrl = 'https://prod.poursoulsystems.com/admin';
	private currentSpoofedSubSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private currentSpoofedSub: string | null = null; //by default uses the auth admin sub, --used for beer library edits

	constructor(private http: HttpClient, private authService: AuthService) {
		// Subscribe to the BehaviorSubject to update currentSpoofedSub whenever it emits a new value
    this.currentSpoofedSubSubject.subscribe((newSub) => {
      this.currentSpoofedSub = newSub;
    });
	}
		
	//try and sync kegs and taps with API
	sendSyncRequest(payload: SyncPayload): Observable<any> {
		const userSub = this.currentSpoofedSub;
		if (!userSub) {
      console.error('No spoofed user sub available.');
      return throwError(new Error('No spoofed user sub available.'));
    }
		 payload.sub = userSub;
	
    return this.http.post(`${this.baseUrl}/tap/sync`, payload).pipe(
      catchError((error) => {
        console.error('Error in sync request:', error);
        return throwError(error);
      })
    );
  }

	updateFcmToken(token: any): Observable<any> {
		return this.http.put(`${this.baseUrl}/user/updateFcmToken`, token).pipe(
			catchError((error) => {
				console.error('Error updating FCM token:', error);
				return throwError(error);
			})
		);
	}
	
	editDuplicate(beerData: any): Observable<any> {
		console.log("Updating duplicate beer details...");
		// Implement the API call logic here, e.g., HTTP PUT request
		return this.http.put(`${this.baseUrl}/beer/editDuplicate`, beerData).pipe(
			catchError((error) => {
				console.error('Error editing duplicate:', error);
				return throwError(error);
			})
		);
	}
	
	mergeDuplicates(mergeData: any): Observable<any> {
		console.log("Merging duplicate beers...");
		// Implement the API call logic here, e.g., HTTP POST request
		return this.http.post(`${this.baseUrl}/beer/merge`, mergeData).pipe(
			catchError((error) => {
				console.error('Error merging duplicates:', error);
				return throwError(error);
			})
		);
	}
	
	confirmDuplicate(confirmData: any): Observable<any> {
		console.log("Confirming duplicate beer...");
		// Implement the API call logic here, e.g., HTTP PATCH request
		return this.http.patch(`${this.baseUrl}/beer/confirm`, confirmData).pipe(
			catchError((error) => {
				console.error('Error confirming duplicate:', error);
				return throwError(error);
			})
		);
	}
	
  setSpoofedUserSub(sub: string) {
    this.currentSpoofedSubSubject.next(sub);
  }
  
	fetchDashboardData(): Observable<any> {
	  const userSub = this.currentSpoofedSub;
		if (!userSub) {
      console.error('No spoofed user sub available.');
      return throwError(new Error('No spoofed user sub available.'));
    }
	
		return this.http.get(`${this.baseUrl}/user/dashboard/${userSub}`).pipe(
			catchError((error) => {
				console.error('Error fetching dashboard data:', error);
				return throwError(error);
			})
		);
  }
	
	fetchKegLevels(): Observable<any> {
    if (!this.currentSpoofedSub) {
      console.error('No spoofed user sub available.');
      return throwError(new Error('No spoofed user sub available.'));
    }

    return this.http.get(`${this.baseUrl}/keg/levelsv2/${this.currentSpoofedSub}`).pipe(
      catchError((error) => {
        console.error('Error fetching keg levels:', error);
        return throwError(error);
      })
    );
  }

  // Get all users details
  getUser(): Observable<any> {
	  if (!this.currentSpoofedSub) {
      console.error('No spoofed user sub available.');
      return throwError(new Error('No spoofed user sub available.'));
    }
		return this.http.get(`${this.baseUrl}/user/${this.currentSpoofedSub}`).pipe(
			catchError((error) => {
				console.error('Error getting user:', error);
				return throwError(error);
			})
		);
  }
	
	// Get user details
  getAllUsers(): Observable<any> {
		return this.http.get(`${this.baseUrl}/user/all`).pipe(
			catchError((error) => {
				console.error('Error getting all users:', error);
				return throwError(error);
			})
		);
  }
	
	// Get all pour data from completed/tapped kegs
  getAllPours(): Observable<any> {
		return this.http.get(`${this.baseUrl}/keg/allPours/${this.currentSpoofedSub}`).pipe(
			catchError((error) => {
				console.error('Error getting all pour data:', error);
				return throwError(error);
			})
		);
  }
	
	  // Register a new user
  createUser(userData: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/user`, userData).pipe(
			catchError((error) => {
				console.error('Error creating new user:', error);
				return throwError(error);
			})
		);
  }
	
	createTap(tapData: any): Observable<any> {
		tapData.sub = this.currentSpoofedSub;
		return this.http.post(`${this.baseUrl}/tap`, tapData).pipe(
			catchError((error) => {
				console.error('Error creating new tap:', error);
				return throwError(error);
			})
		);
	}
  
  // Keg routes
  createKeg(kegData: any): Observable<any> {
		kegData.sub = this.currentSpoofedSub;
		return this.http.post(`${this.baseUrl}/keg`, kegData).pipe(
			catchError((error) => {
				console.error('Error creating keg:', error);
				return throwError(error);
			})
		);
  }
	
	createBeer(beerData: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/beer`, beerData).pipe(
			catchError((error) => {
				console.error('Error creating beer:', error);
				return throwError(error);
			})
		);
	}
	
	createBrewery(breweryData: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/brewery`, breweryData).pipe(
			catchError((error) => {
				console.error('Error creating brewery:', error);
				return throwError(error);
			})
		);
	}
	
	nextKeg(tapId: string, swapTime?: number): Observable<any> {
		// Define the body with an explicit type that includes both swapTime and sub
		let body: { swapTime?: number, sub?: string } = {};

		// Assign swapTime if provided
		if (swapTime !== undefined) {
			body.swapTime = swapTime;
		}

		// Assign the current spoofed sub
		body.sub = this.currentSpoofedSub;

		return this.http.post(`${this.baseUrl}/tap/${tapId}/nextKeg`, body).pipe(
			catchError((error) => {
				console.error('Error setting next keg:', error);
				return throwError(error);
			})
		);
	}
	
	// update kegQueue with user selections
	updateTapKegQueue(tapId: string, kegQueue: string[]): Observable<any> {
		// Include the spoofed sub in the request body
		const requestBody = {
			sub: this.currentSpoofedSub,
			kegQueue: kegQueue
		};
		
		return this.http.put(`${this.baseUrl}/tap/${tapId}/kegQueue`, requestBody ).pipe(
			catchError((error) => {
				console.error('Error updating tap keg queue:', error);
				return throwError(error);
			})
		);
	}

	// update keg by id
	updateKegById(kegId: string, updateData: any): Observable<any> {
		updateData.sub = this.currentSpoofedSub;
		return this.http.put(`${this.baseUrl}/keg/${kegId}`, updateData).pipe(
			catchError((error) => {
				console.error('Error updating keg:', error);
				return throwError(error);
			})
		);
	}
	
	// update user
	updateUser(updateData: any): Observable<any> {
		console.log("backend service now..........updating user.........");
		updateData.sub = this.currentSpoofedSub;
		return this.http.put(`${this.baseUrl}/user`, updateData).pipe(
			catchError((error) => {
				console.error('Error updating user:', error);
				return throwError(error);
			})
		);
	}

  deleteKeg(id: string): Observable<any> {

    return this.http.delete(`${this.baseUrl}/keg/${id}`).pipe(
			catchError((error) => {
				console.error('Error deleting keg:', error);
				return throwError(error);
			})
		);
  }


  getAllKegs(): Observable<any> {

    return this.http.get(`${this.baseUrl}/keg/${this.currentSpoofedSub}`);
  }

  getKegById(id: string): Observable<any> {

    return this.http.get(`${this.baseUrl}/keg/id/${id}`);
  }
	
	// Cleaning Event routes
  createCleaningEvent(eventData: any): Observable<any> {
	  eventData.sub = this.currentSpoofedSub;
    return this.http.post(`${this.baseUrl}/cleaning`, eventData).pipe(
      catchError((error) => {
        console.error('Error creating cleaning event:', error);
        return throwError(error);
      })
    );
  }

  deleteCleaningEvent(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/cleaning/${id}`).pipe(
      catchError((error) => {
        console.error('Error deleting cleaning event:', error);
        return throwError(error);
      })
    );
  }

  updateCleaningEventById(eventId: string, updateData: any): Observable<any> {
	  updateData.sub = this.currentSpoofedSub;
    return this.http.put(`${this.baseUrl}/cleaning/${eventId}`, updateData).pipe(
      catchError((error) => {
        console.error('Error updating cleaning event:', error);
        return throwError(error);
      })
    );
  }

  getAllCleaningEvents(): Observable<any> {
    return this.http.get(`${this.baseUrl}/cleaning/${this.currentSpoofedSub}`).pipe(
      catchError((error) => {
        console.error('Error fetching cleaning events:', error);
        return throwError(error);
      })
    );
  }
	
	returnAllKegsToInventory(tapId: string): Observable<any> {
		const requestBody = {
			sub: this.currentSpoofedSub
		};

		return this.http.post(`${this.baseUrl}/tap/${tapId}/returnAllKegsToInventory`, requestBody).pipe(
			catchError((error) => {
				console.error('Error returning all kegs to inventory with pours:', error);
				return throwError(error);
			})
		);
	}
	
	//backend service functions for CRUD pricing profiles
	createPricingProfile(profileData: any): Observable<any> {
		profileData.sub = this.currentSpoofedSub;
    return this.http.post(`${this.baseUrl}/pricing`, profileData).pipe(
      catchError((error) => {
        console.error('Error creating pricing profile:', error);
        return throwError(error);
      })
    );
  }

  updatePricingProfile(profileId: string, profileData: any): Observable<any> {
		profileData.sub = this.currentSpoofedSub;
    return this.http.put(`${this.baseUrl}/pricing/${profileId}`, profileData).pipe(
      catchError((error) => {
        console.error('Error updating pricing profile:', error);
        return throwError(error);
      })
    );
  }

  deletePricingProfile(profileId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/pricing/${profileId}`).pipe(
      catchError((error) => {
        console.error('Error deleting pricing profile:', error);
        return throwError(error);
      })
    );
  }
	
	getLogs(): Observable<any> {
    return this.http.get(`${this.baseUrl}/user/logs/${this.currentSpoofedSub}`).pipe(
      catchError((error) => {
        console.error('Error logs:', error);
        return throwError(error);
      })
    );
  }
  
}


